<div class="app-wrap">
  <header class="app-header top-bar">
    <nav class="navbar navbar-expand-md">
      <!-- begin navbar-header -->
      <div class="navbar-header d-flex align-items-center justify-content-center">
        <a id="tog" href="javascript:void(0);" class="mobile-toggle" (click)="toggleme()"><i
            class="fa fa-align-right"></i></a>
        <a class="navbar-brand" href="index.html">
          <img src="../../../../assets/images/logo_white.png" width="30" height="30" class="img-fluid" alt="logo" />
          <img src="../../../../assets/images/logo_white.png" class="img-fluid logo-mobile" alt="logo" />
        </a>
      </div>
      <button class="navbar-toggler collapsed" type="button" routerLink="profile">
        <img
          src="https://investx-admin-template.multipurposethemes.com/New_InvestX-main-files/html/images/avatar/avatar-13.png"
          alt="avtar-img" style="height: 30px;" />
      </button>
      <!-- end navbar-header -->

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="navigation d-flex">

          <ul class="navbar-nav nav-right ml-auto">

            <li class="nav-item dropdown user-profile">
              <a href="javascript:void(0)" class="nav-link dropdown-toggle " id="navbarDropdown4" role="button"
                routerLink="profile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img
                  src="https://investx-admin-template.multipurposethemes.com/New_InvestX-main-files/html/images/avatar/avatar-13.png"
                  alt="avtar-img">
                <span class="bg-success user-status"></span>
              </a>

            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>

  <div class="app-container">
    <!-- begin app-nabar -->
    <aside class="app-navbar">
      <!-- begin sidebar-nav -->
      <div class="sidebar-nav scrollbar scroll_light mCustomScrollbar _mCS_3 mCS-autoHide"
        style="position: relative; overflow: visible">
        <div id="mCSB_3" class="mCustomScrollBox mCS-minimal mCSB_vertical mCSB_outside" style="max-height: none"
          tabindex="0">
          <div id="mCSB_3_container" class="mCSB_container" style="position: relative; top: 0; left: 0" dir="ltr">
            <!-- <ul class="metismenu" id="sidebarNav">
              <li class="nav-static-title">Personal</li>
              <li class="active">
                <a
                  class="has-arrow"
                  href="javascript:void(0)"
                  aria-expanded="false"
                >
                  <i class="nav-icon fa fa-rocket"></i>
                  <span class="nav-title">Dashboards</span>
                  <span class="nav-label label label-danger">9</span>
                </a>
              </li>
              <li>
                <a href="app-chat.html" aria-expanded="false"
                  ><i class="nav-icon fa fa-comment"></i
                  ><span class="nav-title">Chat</span></a
                >
              </li>
              <li>
                <a
                  class="has-arrow"
                  href="javascript:void(0)"
                  aria-expanded="false"
                  ><i class="nav-icon fa fa-calendar"></i
                  ><span class="nav-title">Calendar</span></a
                >
                <ul aria-expanded="false" class="collapse">
                  <li><a href="calendar-full.html">Full Calendar</a></li>
                  <li><a href="calendar-list.html">Calendar List</a></li>
                </ul>
              </li>
              <li>
                <a href="mail-inbox.html" aria-expanded="false"
                  ><i class="nav-icon fa fa-email"></i
                  ><span class="nav-title">Mail</span></a
                >
              </li>
              <li>
                <a
                  class="has-arrow"
                  href="javascript:void(0)"
                  aria-expanded="false"
                  ><i class="nav-icon fa fa-bag"></i>
                  <span class="nav-title">UI Kit</span></a
                >
                <ul aria-expanded="false" class="collapse">
                  <li><a href="ui-alerts.html">Alerts</a></li>
                  <li><a href="ui-accordions.html">Accordions</a></li>
                  <li>
                    <a class="has-arrow" href="javascript: void(0);">Buttons</a>
                    <ul aria-expanded="false" class="collapse">
                      <li><a href="ui-button.html">Default Buttons</a></li>
                      <li><a href="ui-button-icon.html">Icon Buttons</a></li>
                      <li><a href="ui-button-block.html">Block Buttons</a></li>
                      <li>
                        <a href="ui-button-social.html">Social Buttons</a>
                      </li>
                      <li><a href="ui-button-groups.html">Group Buttons</a></li>
                    </ul>
                  </li>
                  <li><a href="ui-badges.html">Badges</a></li>
                  <li><a href="ui-cards.html">Cards</a></li>
                  <li><a href="ui-carousel.html">Carousel</a></li>
                  <li><a href="ui-dropdowns.html">Dropdowns</a></li>

                  <li><a href="ui-grid.html">Grid</a></li>
                  <li><a href="ui-list-group.html">List Group</a></li>
                  <li><a href="ui-lightbox.html">Light Box</a></li>
                  <li><a href="ui-modals.html">Modals</a></li>
                  <li><a href="ui-media.html">Media</a></li>
                  <li><a href="ui-nestable-list.html">Nestable List</a></li>
                  <li><a href="ui-pagination.html">Pagination</a></li>
                  <li><a href="ui-progressbars.html">Progress Bars</a></li>

                  <li><a href="ui-sweet-alert.html">Sweet Alert</a></li>
                  <li><a href="ui-tabs.html">Tabs</a></li>
                  <li><a href="ui-nav.html">Nav</a></li>
                  <li>
                    <a href="ui-tooltips-popovers.html"
                      >Tooltips &amp; Popovers</a
                    >
                  </li>
                  <li><a href="ui-typography.html">Typography</a></li>
                  <li><a href="ui-toastr.html">Toastr</a></li>
                  <li><a href="ui-video.html">Video</a></li>
                </ul>
              </li>
              <li>
                <a
                  class="has-arrow"
                  href="javascript:void(0)"
                  aria-expanded="false"
                  ><i class="nav-icon fa fa-info"></i
                  ><span class="nav-title">Icons</span>
                </a>
                <ul aria-expanded="false" class="collapse">
                  <li>
                    <a href="icons-cryptocurrency.html">Cryptocurrency Icons</a>
                  </li>
                  <li><a href="icons-drip.html">Drip Icons</a></li>
                  <li><a href="icons-dash.html">Dash Icons</a></li>
                  <li><a href="icons-feather.html">Feather Icons</a></li>
                  <li><a href="icons-fontawesome.html">Font Awesome</a></li>
                  <li><a href="icons-ion.html">Ion Icons</a></li>
                  <li><a href="icons-weather.html">Weather Icons</a></li>
                  <li><a href="icons-material.html">Material Icons</a></li>
                  <li><a href="icons-themify.html">Themify Icons</a></li>
                </ul>
              </li>
            </ul> -->

            <ul class="metismenu side-nav mm-show">
              <li class="menu-title">
                {{ user }} <i>${{ acc | number }}</i>
              </li>
              <li class="vb__menuLeft__category">
                <a class="has-arrow active" (click)="togmenu(1)" routerLink="/dashboard" aria-expanded="false">
                  <i class="nav-icon fa fa-rocket"></i>
                  <span class="nav-title">Dashboard</span>
                </a>
              </li>

              <li class="vb__menuLeft__category">
                <a class="has-arrow active" (click)="togmenu(4)" [routerLink]="['deposit']"
                  [queryParams]="{ dep: 'invest' }">
                  <i class="nav-icon fa fa-money"></i>
                  <span class="nav-title">Invest</span>
                </a>
              </li>


              <li class="vb__menuLeft__category">
                <a class="has-arrow active" (click)="togmenu(4)" routerLink="capture">
                  <i class="nav-icon fa fa-money"></i>
                  <span class="nav-title">Citizenship by Investment </span>
                </a>
              </li>

              <li class="vb__menuLeft__category">
                <a class="has-arrow active" (click)="togmenu(4)" [routerLink]="['deposit']"
                  [queryParams]="{ dep: 'history' }">
                  <i class="nav-icon fa fa-money"></i>
                  <span class="nav-title">Investment History</span>
                </a>
              </li>

              <li class="vb__menuLeft__category">
                <a class="has-arrow active" (click)="togmenu(4)" routerLink="withdrawal">
                  <i class="nav-icon fa fa-money"></i>
                  <span class="nav-title">Withdrawal</span>
                </a>
              </li>
              <li class="vb__menuLeft__category">
                <a class="has-arrow active" (click)="togmenu(4)" routerLink="history">
                  <i class="nav-icon fa fa-money"></i>
                  <span class="nav-title">Transaction History</span>
                </a>
              </li>
              <li class="side-nav-item">
                <a href="javascript: void(0);" (click)="logOut()" class="side-nav-link">
                  <i class="fa fa-power-off"></i>
                  <span>Logout </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- end sidebar-nav -->
    </aside>
    <!-- end app-navbar -->
    <!-- begin app-main -->

    <!-- style="background-color: #E6EBEE !important;" -->

    <div class="app-main" id="main">
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </div>
    <!-- end app-main -->
  </div>
</div>